import React from "react"
import { graphql } from "gatsby"
import { Layout, Head as HeadComponent } from "@bw/layouts"
import { Spacer, Section, CmsImage, Grid } from "@bw/bits"
import { Cartouche, TeaserProfile } from "@bw/partials"
import { useTranslation } from "react-i18next"
import { PillMarker } from "@bw/icons"
import PoiModel from "models/poi"
import { Header, Content } from "@bw/modules/mapModules"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title={data.page.metaTitle || data.page.title}
    image={data.page.image?.localImage?.childImageSharp?.original?.src}
    location={location}
    pageContext={pageContext}
    description={data.page.metaDescription}
    doNotIndex={data.page.doNotIndex}
  />
)

const PoiTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, mount } = data
  const Poi = new PoiModel(page)

  /**
   * Generate the breadcrumbs
   */
  const breadcrumbsLinks = React.useMemo(() => {
    const links = [
      {
        title: t("homepage"),
        to: "/",
      },
    ]

    if (mount) {
      links.push({
        title: mount.title,
        to: mount.slug,
      })
    }

    links.push({
      title: page.title,
      to: page.slug,
    })

    return links
  }, [t, mount, page])

  const [line1, line2] = Poi.getAddressLines()
  const todayOpeningHours = Poi.getTodayOpeningHours()

  return (
    <Layout {...{ pageContext }}>
      {/*
       * Loading the Header module, ex Hero or poiHeader
       */}
      <Header
        modules={Poi.header}
        page={Poi}
        pageContext={pageContext}
        breadcrumbsLinks={breadcrumbsLinks}
      />
      <Section>
        <Cartouche>
          <a
            href={
              "https://www.google.ch/maps/dir//" +
              encodeURIComponent(
                [Poi.street, Poi.zipCode, Poi.city, Poi.country].join(" ")
              )
            }
            target="_blank"
            rel="noreferrer"
          >
            <Spacer direction="row">
              <PillMarker />
              <address>
                {line1}
                <br />
                {line2}
              </address>
            </Spacer>
          </a>
          {(Poi.email || Poi.phone) && (
            <div>
              {Poi.email && (
                <div>
                  <a href={`mailto:${Poi.email}`}>{Poi.email}</a>
                </div>
              )}
              {Poi.phone && (
                <div>
                  <a href={`tel:${Poi.phone}`}>{Poi.phone}</a>
                </div>
              )}
            </div>
          )}
          {Poi.hasOpeningHours() && (
            <details>
              <summary>
                <span style={{ fontWeight: "500" }}>{t("dates:today")}:</span>{" "}
                {PoiModel.formatOpeningHours(todayOpeningHours) || t("closed")}
              </summary>
              {Object.keys(Poi.openingHoursByDay).map(day => (
                <div style={{ marginTop: "5px" }}>
                  <span style={{ fontWeight: "500" }}>
                    {t(`dates:${day}`)}:{" "}
                  </span>
                  {PoiModel.formatOpeningHours(Poi.openingHoursByDay[day]) ||
                    t("closed")}
                </div>
              ))}
            </details>
          )}
        </Cartouche>
      </Section>
      {Poi.hasProfiles() && (
        <Section suptitle={t("poi.team.suptitle")} title={t("poi.team.title")}>
          <Grid columns={4}>
            {Poi.profiles.map(profile => (
              <TeaserProfile
                key={profile.url}
                to={profile.url}
                firstname={profile.firstName}
                lastname={profile.title}
                role={profile.role}
                portrait={
                  <CmsImage src={profile.portrait} alt={profile.portrait.alt} />
                }
              />
            ))}
          </Grid>
        </Section>
      )}
      {/*
       * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
       */}
      <Content modules={Poi.content} />
    </Layout>
  )
}

export default PoiTemplate

export const poiQuery = graphql`
  query poiQuery($id: String!) {
    page: collectionPois(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        localImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      doNotIndex

      teaserImage {
        url
      }

      street
      city
      zipCode
      state
      country
      phone
      email
      latitude
      longitude
      openingHours {
        days {
          value
        }
        periods {
          from
          to
        }
      }

      profiles {
        url
        title
        firstName
        role
        portrait {
          alt
          localImage {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }

      header {
        __typename
        suptitle
        title
        subtitle

        ...HeroModuleFragment
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ...CallToActionModuleFragment
        ...ContentModuleFragment
        ...ContentWithImageModuleFragment
        ...GalleryModuleFragment
        ...ContentOnColumnsModuleFragment
        ...DownloadsModuleFragment
        ...TeasersManualModuleFragment
        ...LogoGridModuleFragment
        ...ImageModuleFragment
        ...VideoModuleFragment
        ...FormModuleFragment
        ...TimelineModuleFragment
        ...ListOfPoisModuleFragment
        ...ListOfArticlesModuleFragment
        ...ListOfProfilesModuleFragment
        ...ListOfJobsModuleFragment
        ...ListOfEventsModuleFragment
        ...PredefinedModuleModuleFragment
      }
    }
    mount: collectionPages(mount: { handle: { eq: "pois" } }) {
      slug
      title
    }
  }
`
